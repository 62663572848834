@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  display: none;
}

body {
  background-color: #111827;
}
